.view-document {
  position: relative;
  z-index: 1000;
  background: white;
  border: 1px solid #333;
  margin: 10px auto;
  width: 1200px;
  min-height: 800px;
}

.view-document object {
  border: 1px solid #BBB;
  width: 100%;
  height: 700px;
  padding: 10px;
}

.buttons {
  margin: 27px auto;
  width: 400px;
}

.buttons button {
  width: 180px;
  padding: 9px 7px;
  margin: 0 10px;
}
