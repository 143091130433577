:root {
    --theme_color: linear-gradient(90deg, #ca2bf7 0%, #04d4e0 100%);
    --theme_shadow: 1px 5px 7px hsl(183, 88%, 84%);
    --button_color: #ca2bf7;
}
/* ---------------------------------------------------------------------------- */

.copra-top-nav {
    background: transparent;
    background-image: var(--theme_color);
    height: 70px;
}

.left-nav-item {
    display: inline-block;
    vertical-align: top;
    color: white;
    padding: 10px 10px 7px 5px;
    font-size: 18px;
    height: 55px;
}

.nav-logo {
    position: relative;
    width: 120px;
    margin-top: 16px;
    left: 33%;
}

.right-nav-items {
    color: white;
    padding: 10px 5px 0px 10px;
    font-size: 18px;
    float: right;
}

.nav-logout {
    clear: both;
    margin-top: 6px;
    background: var(--button_color);
    color: white;
    font-size: 12px;
    border: 1px solid transparent;
    border-radius: 8px;
    float: right;
}
.nav-logout:hover {
    font-weight: bold;
    color: white;
}

.report-issue-container a {
    display: inline-block;
    color: white;
    text-decoration: underline;
    margin-top: 6px;
}

.report-issue-container:hover {
    color: white;
    font-weight: bold;
}
