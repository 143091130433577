* {
    box-sizing: border-box;
    font-family: 'DM sans';
}

body {
    font-size: 14px;
    margin: 0;
    padding: 0;
}

.copra-container {
    margin: 0;
    padding: 0;
}

.col-50 {
    display: inline-block;
    width: 50%;
}

.btn {
    background-color: #345995;
    color: white;
    border: 0;
    padding: 4px 8px;
    font-weight: bold;
}

img.logo {
    width: 220px;
    margin: 25px auto;
    display: block;
}
