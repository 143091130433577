:root {
    --theme_light_color: linear-gradient(180deg, #fae9ff 0%, #e4feff 100%);
    --theme_shadow: 1px 5px 7px hsl(183, 88%, 84%);
}

/* ---------------------------------------------------------------------------- */

.wrapper {
    display: flex;
    align-items: center;
    background: var(--theme_light_color);
    height: 100vh;
    width: 100vw;
}

.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 407px;
    min-height: 450px;
    margin: 200px auto;
    border-radius: 10px;
}

.login-form {
    border-radius: 10px;
    width: 400px;
    height: 520px;
    background-color: white;
    padding: 10px;
    position: relative;
    z-index: 1;
}

.login-form h1 {
    font-size: 18px;
    font-weight: bold;
    margin: 10px 10px 0px 10px;
    padding: 5px 5px;
    color: black;
    display: inline-block;
}

.login-error {
    padding: 10px;
    margin-left: 17px;
    border-radius: 6px;
    background-color: #500;
    color: white;
    width: 25rem;
}

.login-result {
    padding: 15px;
    color: white;
}

.login-welcome {
    padding: 15px;
    color: black;
}

.login-form label {
    display: block;
    text-align: left;
    color: black;
    margin-top: 25px;
    margin-left: 16px;
}

.login-form label input {
    width: 350px;
    height: 40px;
    padding: 10px 25px;
    margin: 10px 40px 10px 0px;
    background: rgb(232, 240, 254);
    border-radius: 6px;
    border: none;
}

input:focus {
    outline: solid 1px black;
}

.login-form .login-button {
    padding: 14px;
    background-color: #ca2bf7;
    border: transparent 1px solid;
    border-radius: 6px;
    color: white;
    width: 92.3%;
    margin-left: 16px;
    height: 50px;
    font-size: 1.175em;
}

.login-form .login-button:hover {
    background-color: #9800c5;
}

.login-form button {
    height: 28px;
    margin: 30px 40px 5px 18px;
}

.login-form .forgot-link {
    clear: both;
    background: transparent;
    color: black;
    border: none;
    text-decoration: underline;
    margin-left: 12px;
}

.clear {
    clear: both;
}
