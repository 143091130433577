:root {
    --theme_shadow: 1px 5px 7px hsl(183, 88%, 84%);
    --light_blue: rgba(119, 162, 219, 0.7);
}

/* ---------------------------------------------------------------------------- */

body {
    background-color: #eee;
    height: 100vh;
}

.flex {
    display: flex;
}

#approve-btn {
    font-size: 14px;
    margin-top: 8px;
    background-color: var(--button_color);
    border-radius: 4px;
    padding: 5px 12px;
    font-weight: 400;
    border: 1px solid #ca2bf7;
}

#approve-btn:hover {
    background-color: #9800c5;
}

.home-filters {
    margin: 10px 0 0 10px;
}

.name-filter,
.state-filter {
    display: inline-block;
    margin-right: 40px;
}

.name-filter input {
    width: 300px;
    margin-left: 10px;
    padding: 2px 3px;
    border: 1px solid #bbb;
}

.state-filter select {
    width: 150px;
    margin-left: 10px;
    padding: 2px 3px;
    border: 1px solid #bbb;
}

.timesheet-list-container {
    margin: 10px;
}

.timesheet-list {
    width: 100%;
    display: inline-block;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 -1px 3px -2px rgba(0, 0, 0, 0.2),
        0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.timesheet-list-heading {
    color: #537199;
    font-size: 16px;
    padding: 10px 0px 10px 0px;
    border-radius: 6px 6px 0px 0px;
    text-transform: uppercase;
    font-weight: 600;
}

.timesheet-list table {
    width: 100%;
    border-collapse: collapse;
}

.timesheet-list td,
.timesheet-list th {
    padding: 6px;
    font-weight: normal;
}

.ts-name {
    width: 180px;
}

.ts-dnw {
    text-align: right;
}

.ts-dnw button {
    margin-right: 30px;
}

.ts-small {
    width: 55px;
}

.timesheet-list-row:hover {
    background: #ddd;
    cursor: pointer;
}

.timesheet-list-thead {
    background: rgb(119, 162, 219, 0.7);
    color: black;
}
