.export {
    margin: 0;
    padding: 10px;
}

.export .btn {
    font-size: 14px;
    margin: 20px 0px;
    background-color: var(--button_color);
    border-radius: 4px;
    padding: 5px 12px;
    font-weight: 400;
    border: 1px solid #ca2bf7;
}
.export .btn:hover {
    background-color: #9800c5;
}

.result {
    font-weight: bold;
}

.export-error {
    color: red;
    font-weight: bold;
}

.log {
    color: #555;
}

.dropdown {
    width: 150px;
}
