* {
    font-size: 14px;
}

body {
    background: #fff;
}

.app {
    margin: 50px;
}

.contract {
    vertical-align: top;
    width: 40%;
    margin: 10px;
    padding: 10px;
    min-height: 500px;
    display: inline-block;
    background: white;
}

.contract h2 {
    text-align: center;
}

.cta {
    margin: 10px 40px;
    text-align: center;
}

.error {
    margin: 20px;
    background: #800;
    color: white;
    padding: 10px;
}

.cta button {
    width: 50%;
}

.top-nav {
    padding: 5px 7px;
    background-image: url(./icons/btnbar_start.png);
    background-position: left center;
    background-repeat: no-repeat;
}

.top-nav button {
    width: 26px;
    height: 23px;
    border: none;
    vertical-align: middle;
}

.top-nav-spacer {
    display: inline-block;
    vertical-align: middle;
    background-image: url(./icons/btnbar_spacer.png);
    width: 5px;
    height: 23px;
}

.btn-back {
    background-image: url(./icons/btnbar_back.png);
    width: 24px;
}

.btn-extend {
    background-image: url(./icons/btnbar_extend.png);
}

.btn-save {
    background-image: url(./icons/btnbar_save.png);
}

.btn-stop {
    background-image: url(./icons/btnbar_stop.png);
    width: 22px;
}

.btn-delete {
    background-image: url(./icons/btnbar_delete.png);
    width: 22px;
}

.btn-cross {
    background-image: url(./icons/btnbar_cross.png);
    width: 22px;
}

.icon-mail {
    background-image: url(./icons/icon-mail.png);
    width: 14px;
    height: 10px;
}

table th {
    text-align: left;
}

.small {
    font-size: 13px;
}

.bold {
    font-weight: bold;
}

.btn-symbol {
    font-size: 25px;
    line-height: 20px;
}

button:enabled {
    cursor: pointer;
}

button[disabled] {
    opacity: 0.5;
}

.head {
    padding: 1px 1px 4px 5px;
    font-size: 18px;
    font-weight: bold;
}

.float-right {
    float: right;
}

.emoji {
    font-size: 27px;
    line-height: 18px;
}

.border-red {
    border: 1px solid #c00;
}

.bg-red {
    background: #c00;
}

.bg-grey {
    background: #999;
}

.bg-l-grey {
    background: #ccc;
}

.white {
    color: white;
}

.red {
    color: #a00;
}

.orange {
    color: #b70;
}

.inline-block {
    display: inline-block;
}
