.modal.overlay {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(190, 190, 190, 0.5);
}

.modal.container {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
}

.modal.dialog {
    background: white;
    margin: 10px auto;
    width: 680px;
}

.modal-section {
    margin: 10px;
}

.modal-section button {
    margin-right: 10px;
}

.modal-info {
    margin: 20px 0;
}
