.tooltip-container {
  display: inline-block;
  margin: 1px 5px;
  vertical-align: top;
  position: relative;
}

.tooltip {
  position: absolute;
  top: -20px;
  left: 20px;
  width: 250px;
  padding: 6px;
  background: white;
  border: 1px solid #ccc;
  box-shadow: 1px 1px 2px #aaa;
}
