.approve-all-timesheets {
    position: relative;
    z-index: 1000;
    background: white;
    border: 1px solid #333;
    margin: 10px auto;
    padding: 50px 50px;
    width: 600px;
    min-height: 400px;
}

.total-timesheets {
    margin: 20px 0;
}

.timesheets-btns {
    margin-top: 80px;
}
