.tabs {
    margin: 5px 0;
}

.tabs > button {
    outline: none;
    border: 1px solid black;
    background: #ddd;
}

.tabs > button.selected {
    background: white;
    border-bottom: none;
}
