.pagination {
    width: 250px;
    margin: 10px auto;
    height: 20px;
}

.pagination button {
    border: none;
    outline: none;
    background: none;
}

.sort-btn {
    background: transparent;
    padding: 0px;
    border: 0;
    line-height: 22px;
}

.sort-btn span {
    vertical-align: top;
    font-size: 13px;
}

.sort-btn:hover {
    background: #999;
}

.timesheet-content {
    min-height: 360px;
}
