.timesheet {
    width: 100%;
    margin: 20px 0px;
}

#back-btn {
    background-color: rgb(119, 162, 219, 0.4);
    color: black;
    transition: all 0.3s;
    border: 1px solid rgb(119, 162, 219, 0.4);
}

#back-btn:hover {
    background-color: rgb(119, 162, 219, 0.7);
    border: 1px solid rgb(119, 162, 219, 0.7);
    color: black;
}

.ts-info {
    line-height: 26px;
}

.mail-link {
    margin-left: 7px;
    margin-bottom: 1px;
    vertical-align: middle;
    display: inline-block;
}

.timesheet thead tr th {
    min-width: 120px;
}

.paid-leave-row td {
    padding-top: 10px;
}

.input-validator {
    display: inline-block;
}

.transparent-border {
    border: 1px solid transparent;
}

.timesheet-col input,
.timesheet-col select {
    width: 100px;
}

.timesheet-error {
    margin-left: 20px;
    color: red;
    padding: 5px;
}

.timesheet-message {
    margin-left: 20px;
    color: #345995;
    font-weight: bold;
}

.timesheet-actions button {
    font-size: 14px;
    margin: 8px 8px;
    background-color: var(--button_color);
    border-radius: 4px;
    padding: 5px 12px;
    font-weight: 400;
    border: 1px solid #ca2bf7;
}

.timesheet-actions button:hover {
    background-color: #9800c5;
}

.receipts-label {
    display: inline-block;
    margin-left: 100px;
}

.receipts-label a {
    color: #345995;
}

.timesheet-section {
    padding: 6px 12px;
}

.timesheet-header {
    background-color: rgb(119, 162, 219, 0.7);
    padding-left: 12px;
}

.timesheet-container {
    background: white;
    margin: 10px;
    border-radius: 8px;
    box-shadow: 0 -1px 3px -2px rgba(0, 0, 0, 0.2),
        0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
