.modal-section textarea {
    width: 100%;
    height: 100px;
    margin-bottom: 18px;
}

.modal.dialog {
    margin-top: 30px;
}

.modal-title {
    color: #000;
    background: #77a2db;
    font-size: 18px;
    padding: 8px;
}

.modal-btn {
    font-size: 14px;
    margin: 8px;
    background-color: var(--button_color);
    border-radius: 4px;
    font-weight: 400;
    border: 1px solid #ca2bf7;
    padding: 5px 7px;
    color: white;
}

.modal-back-btn {
    background-color: rgba(119, 162, 219, 0.4);
    color: #000;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    border: 1px solid rgba(119, 162, 219, 0.4);
    padding: 5px 7px;
}
